import React from "react";
import Layout from "../../components/layout/Layout";
import SEO from "../../components/SEO";
import Header from "../../components/layout/Header.js";
import SiteTitleQuery from "../../components/SiteTitleQuery";
import DrawingsImage from "../../images/inHouseImages/drawingsImage.jpg";
import { Link } from "gatsby";
import ProfileCard from "../../components/coworkers/Profile.js";
import Anneli from "../../images/profiles/profileAnneli.jpg";

function Drawings() {
  return (
    <SiteTitleQuery
      render={data => (
        <Layout title={data.site.siteMetadata.title}>
          <SEO title="Ritarbeten" 
              keywords={[`CAD`, `ritning`, `va`, `bygglov`, `klassning`, `cad-ingenjör`, `fältarbete`,]} />
          <Header data={data} />

          <div className="container mx-auto flex flex-col md:flex-row items-center my-8 md:my-10 justify-center">
            <div className="sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-3xl rounded overflow-hidden shadow">
              <img className="w-full" src={DrawingsImage} alt="cad" title="cad"></img>
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">Ritarbeten</div>
                <p className="text-gray-700 text-base">
                  Vi hjälper till att ta fram ritningar efter era behov, t.ex.
                  Plan-, VA- och Bygglovsritningar, Situations- och
                  Klassningsplaner och även Miljötekniska ritningar.
                  <br></br>
                  <br></br>
                  Våra CAD-ingenjörer har tillsammans lång och gedigen
                  erfarenhet inom våra verksamhetsområden.
                  <br></br>
                  Detta innebär att ni inte bara får kompletta ritningar utan
                  att vi även kan bistå med rådgivning kring gällande lagar och
                  regler för att ni ska få ett så optimalt underlag som möjligt.
                  <br></br>
                  <br></br>
                  Finns det behov av platsbesök erbjuder vi{" "}
                  <Link className="text-blue-600" to="/fieldwork">
                    {" "}
                    Fältarbete{" "}
                  </Link>{" "}
                  för att få ett aktuellt underlag att arbeta med.
                </p>
              </div>
              <ProfileCard
                profile={Anneli}
                name="Anneli Andersson"
                email="ritningar@psksyd.com"
              />
            </div>
          </div>
        </Layout>
      )}
    />
  );
}

export default Drawings;
